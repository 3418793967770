// import 'core-js/stable';
// import 'regenerator-runtime/runtime';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

document.getElementById('FirstContentfulPaint').remove();
ReactDOM.render(<App />, document.getElementById('root'));
serviceWorker.register();
