import React from 'react';
import styled from 'styled-components';
import { Route, Router, Switch } from 'react-router-dom';
import { createMemoryHistory } from 'history';
import { CssBaseline, useMediaQuery } from '@material-ui/core';
import {
  unstable_createMuiStrictModeTheme as createMuiTheme,
  MuiThemeProvider,
  StylesProvider,
} from '@material-ui/core/styles';
import deepOrange from '@material-ui/core/colors/deepOrange';

import * as firebase from 'firebase/app';
import 'firebase/auth';

firebase.initializeApp({
  apiKey: 'AIzaSyA7DywZGoF_c-43JrNac4M-4KOovI4bCYw',
  authDomain: 'note.1campus.net',
  databaseURL: 'https://ischool-note.firebaseio.com',
  projectId: 'ischool-note',
  storageBucket: 'ischool-note.appspot.com',
  messagingSenderId: '927408946510',
});

const AppContext = React.createContext({});

const Welcome = React.lazy(() => import('./pages/welcome.js'));
const Main = React.lazy(() => import('./pages/main.js'));

const history = createMemoryHistory();
const Loading = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
`;

const App = () => {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const theme = React.useMemo(() => {
    return createMuiTheme({
      palette: {
        primary: { main: prefersDarkMode ? deepOrange[500] : deepOrange[700] },
        type: prefersDarkMode ? 'dark' : 'light',
      },
    });
  }, [prefersDarkMode]);

  return (
    <StylesProvider injectFirst>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <Router history={history}>
          <React.Suspense fallback={<Loading>Loading...</Loading>}>
            <Switch>
              <Route path="/main" children={<Main />} />
              <Route path="/" children={<Welcome />} />
            </Switch>
          </React.Suspense>
        </Router>
      </MuiThemeProvider>
    </StylesProvider>
  );
};

export default App;
export { AppContext };

try {
  const { access_token, refresh_token, expires_in, state } = JSON.parse(
    `{"${decodeURIComponent(
      window.location.hash.substr(1).replace(/=/g, '":"').replace(/&/g, '","'),
    )}"}`,
  );

  if (access_token && refresh_token && expires_in && state) {
    window.history.pushState({}, '', window.location.origin);

    fetch(
      `https://us-central1-ischool-note.cloudfunctions.net/getCustomToken?accessToken=${access_token}`,
    )
      .then((response) => response.json())
      .then((response) => {
        if (response.error) return;
        firebase.auth().signInWithCustomToken(response.token);
      });
  }
} catch {
  // empty
}

try {
  const { application } = JSON.parse(
    `{"${decodeURIComponent(
      window.location.search
        .substr(1)
        .replace(/=/g, '":"')
        .replace(/&/g, '","'),
    )}"}`,
  );

  if (application === '1campus.net') {
    const params = [
      `client_id=140632d783e5fcc4810c0b08d103ec9b`,
      `response_type=token`,
      `redirect_uri=${window.location.origin}`,
      `scope=${['User.Mail', 'User.BasicInfo', 'User.Application'].join()}`,
      `state=${application}`,
    ].join('&');

    window.location.replace(
      `https://auth.ischool.com.tw/oauth/authorize.php?${params}`,
    );
  }
} catch {
  // empty
}
